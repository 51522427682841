@mixin button-basis(){
  position: relative;
  display: inline-block;
  padding: 0.75rem 2rem;
  font-size: 0.8rem;
  font-weight: bold;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
}

@mixin button($color_txt_brdr: #FFF, $color_bg: $color-primary) {
  @include button-basis();
  background: $color_bg;
  border: 2px solid transparent;
  color: $color_txt_brdr;

  &:hover {
    background: $color_txt_brdr;
    color: $color_bg;
    border: 2px solid $color_bg;
    cursor: pointer;
  }
}

@mixin button--ghost($color_txt_brdr: #FFF, $color_bg: $color-primary) {
  @include button($color_txt_brdr, $color_bg);

  border: 2px solid $color_txt_brdr;

  &:hover {
    background: $color_txt_brdr;
    color: $color_bg;
    border: 2px solid $color_bg;
    cursor: pointer;
  }
}

/**
 *
 * Usage
 * <a button--break><span>Button text</span></a>
 *
 **/

@mixin button--break($color_txt: #FFF, $color_bg: $color-secondary) {
  @include button-basis();
  color: $color_txt;  

  &:focus, &:active {
    outline: none;
  }
  
  span {
    position: relative;
    z-index: 20;

    &:before {
      content: "";
      position: absolute;
      left: 50%;
      top: -4px;
      width: 6px;
      height: 6px;
      background: $color_bg;
      transition: transform 0.3s ease, opacity 0.5s ease-out;
    }

    &:after {
      content: "";
      position: absolute;
      left: 50%;
      top: -4px;
      width: 4px;
      height: 4px;
      background: $color_bg;
      transition: transform 0.3s ease, opacity 0.5s ease-out;
    }
  }

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 50%;
    background: $color_bg;
    transition: 0.3s ease;
  }

  &:after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 50%;
    background: $color_bg;
    transition: 0.3s ease-out;
  }

  &:hover {
    color: $color_txt;

    &:before {
      transform: translate3d(-10px, 3px, 0) rotate(-5deg);
    }

    &:after {
      transform: translate3d(10px, 5px, 0) rotate(3deg);
    }

    span {

      &:before {
        transform: translate3d(-10px, -30px, 0) rotate(-5deg);
        opacity: 0;
      }
  
      &:after {
        transform: translate3d(30px, -40px, 0) rotate(3deg);
        opacity: 0;
      }
    }
  }
}

@mixin button--coins(){
  @include button-basis;
  color: $color-tertiary;
  background: $color-secondary;
  border: 2px solid $color-secondary;  

  &:focus, &:active {
    outline: none;
  }

  span {
    position: relative;
    z-index: 20;

    &:before {
      content: "";
      position: absolute;
      left: -1rem;
      bottom: 0;
      width: 0.8rem;
      height: 0.8rem;
      border-radius: 50%;
      background: $color-secondary;
      transition: transform 0.3s ease, opacity 0.5s ease-out;
    }

    &:after {
      content: "";
      position: absolute;
      right: -1rem;
      bottom: 0;
      width: 0.8rem;
      height: 0.8rem;
      border-radius: 50%;
      background: $color-secondary;
      transition: transform 0.3s ease, opacity 0.6s ease-out;
    }
  }

  &:before {
    content: "";
    position: absolute;
    left: 30%;
    bottom: 0;
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    background: $color-secondary;
    transition: transform 0.3s ease, opacity 0.5s ease-out;
  }

  &:after {
    content: "";
    position: absolute;
    left: 60%;
    bottom: 0;
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    background: $color-secondary;
    transition: transform 0.3s ease, opacity 0.4s ease-out;
  }

  &:hover {
    background: $color-tertiary;
    color: $color-secondary;

    span {
      &:before {
        transform: translate3d(-5px, 40px, 0);
        opacity: 0;
      }
  
      &:after {
        transform: translate3d(7px, 50px, 0);
        opacity: 0;
      }
    }
    &:before {
      transform: translate3d(-5px, 70px, 0);
      opacity: 0;
    }

    &:after {
      transform: translate3d(7px, 60px, 0);
      opacity: 0;
    }
  }
}