.nieuws_lijst {
  $this: &;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 70px;

  .lees_meer {
    min-width: 100%;
    padding: 20px;
    text-align: center;

    button, p {
      display: inline;
      padding: 19px;
      font-size: 14px;
      font-weight: 500;
      line-height: 15px;
      text-align: center;
      color: $color-primary;
      border: 2px solid $color-primary;
      text-transform: uppercase;
      background: none;
      cursor: pointer;

      &:hover {
        background: $color-primary;
        color: $color-tertiary;
      }
    }
  }
}