@mixin info-label {
  display: flex;

  .datum {
    border: 1px solid $color-secondary;
    border-right: none;
    transform: translateX(1px);
    font-size: 0.8rem;
    line-height: 1;
    padding: 5px;
    color: $color-secondary;
  }
  .categorie {
    border: 1px solid $color-secondary;
    background: $color-secondary;
    font-size: 0.8rem;
    line-height: 1;
    padding: 5px;
    color: $color-primary;
    text-decoration: none;

    &:hover {
      background: $color-primary;
      color: $color-secondary;
    }
  }
}

@mixin info-label--ghost {
  
}


@mixin logo--blue {

  .site-logo-img {
    background-image: url(/images/default/svg/logo/PJ_LOGO_blauw.svg);
  }
  
}