@import "../../../css/variables";

.nieuws_module {
  padding: 6.5rem 0 9rem;

  &__titel {
    @include h1--huge;
    color: $color-primary;
    text-align: center;
    margin-bottom: 0 0 10px;

    @include font-inherit;
  }

  &__intro_text {
    color: $color-primary;
    padding-bottom: 50px;
    text-align: center;
  }

  &__button-container {
    text-align: center;
  }

  &__button {
    @include button;
  }

}
@import "../css/list";
@import "../css/item";