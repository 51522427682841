.nieuws_item {
  $item: &;

  width: 30%;
  position: relative;
  width: calc(33% - 0.6rem);
  border: 1px solid $color-primary;
  background: $color-primary;
  margin: 0 0.9rem 0.9rem 0;
  cursor: pointer;

  @include transition-snappy;

  @media (min-width: 992px) {
    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  @media (max-width: 992px) {
    width: 50%;
    width: calc(50% - 0.8rem);

    &:last-child {
      display: none;
    }
  }

  @media (min-width: 768px) and (max-width: 992px) {
    &:nth-child(2n) {
      margin-right: 0;
    }
  }

  @media (max-width: 767px) {
    margin-right: 0;
    width: 100%;
  }

  &__item_content {
    position: relative;
    padding-top: 100%;
  }

  &__item_titel {
    position: absolute;
    left: 30px;
    top: 30px;
    right: 30px;
    bottom: 70px;
    overflow: hidden;
    font-size: 1.8rem;
    line-height: 1.2;
    color: $color-tertiary;
    font-family: $special-font;
  }

  &__item_eigenschappen {
    position: absolute;
    right: 20px;
    left: 20px;
    bottom: 20px;
    display: flex;
    justify-content: flex-end;
    font-size: 0.8rem;
    overflow: hidden;
  }

  &__item_datum {
    border: 1px solid $color-tertiary;
    color: $color-tertiary;
    padding: 0 7px;
    min-width: 80px;
  }

  &__item_categorie {
    position: relative;
    border: 1px solid $color-tertiary;
    border-left: none;
    background: $color-tertiary;
    color: $color-primary;
    padding: 0 7px;
    max-width: calc(100% - 80px);
    overflow: hidden;
    white-space: nowrap;
    font-weight: bold;
    text-transform: uppercase;

    &:after {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      width: 5px;
      height: 100%;
      background: $color-tertiary;
    }
  }

  &__image {
    mix-blend-mode: luminosity;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    filter: grayscale(100%);
    opacity: 0.4;
  }

  &:hover {
    background: transparent;

    #{$item}__image {
      opacity: 0.25;
    }

    #{$item}__item_titel {
      color: $color-primary;
      text-shadow: none;
    }

    #{$item}__item_datum {
      border-color: $color-primary;
      color: $color-primary;
    }
    #{$item}__item_categorie {
      border-color: $color-primary;
      background-color: transparent;

      &:after {
        background-color: transparent;
      }
    }
  }

  &.alt {
    background: $color-tertiary;

    #{$item}__item_titel {
      color: $color-primary;
      text-shadow: 0 0 6px rgba($color: #ffffff, $alpha: 0.3);
      color: #158466;
    }

    #{$item}__item_datum {
      border: 1px solid $color-primary;
      color: $color-primary;
    }

    &:hover {
      background: $color-primary;

      #{$item}__item_titel {
        color: $color-tertiary;
        text-shadow: none;
      }

      #{$item}__item_datum {
        border: 1px solid $color-tertiary;
        color: $color-tertiary;
      }
    }
  }

  @media (min-width: 768px) and (max-width: 992px) {
    &:nth-child(8n-6),
    &:nth-child(8n-5),
    &:nth-child(8n-2),
    &:nth-child(8n-1) {
      background: $color-tertiary;

      #{$item}__item_titel {
        color: $color-primary;
      }

      &:hover {
        background: $color-primary;

        #{$item}__item_titel {
          color: $color-tertiary;
        }
      }
    }
  }

  @media (max-width: 768px) {
    &:nth-child(even) {
      background: $color-tertiary;

      #{$item}__item_titel {
        color: $color-primary;
      }

      &:hover {
        background: $color-primary;

        #{$item}__item_titel {
          color: $color-tertiary;
        }
      }
    }
  }
}
