$body-background: #fefefe;

$footer-background: #06143A;
$footer-text-color: #fff;
$footer-link-color: #fff;

$color-bg-accent: #efefef;
$color-text-root: #222;
$color-text-root-accent: #555;

$color-primary: #2447AA;
$color-secondary: #D8A125;
$color-tertiary: #F6E3D2;

$pattern-1: url("/images/default/paterns/main-pattern.svg");
$pattern-2: url("/images/default/paterns/pattern-1.svg");

$basic-font: 'Montserrat', sans-serif;
$special-font: 'Alice', serif;


@mixin font-inherit {
  h1, h2, h3, h4, h5, h6, p {
    font-size: inherit;
    line-height: inherit;
    font-weight: inherit;
    font-family: inherit;
    color: inherit;
    margin: inherit;
  }
}

@mixin no-spacing {
  h1, h2, h3, h4, h5, h6, p {
    margin: 0;
    padding: 0;
  }
}


@import './mixins/all-mixins';


/* CONTENT ELEMENTS */

$element-margin: 2rem 0;

@import '../../../components/com_lux_pages/assets/css/integration/_lux_integration';
@import './template_variables';