@mixin transition-default {
  transition: 0.5s ease-in-out;
}

@mixin transition-snappy {
  transition: 0.3s ease-in-out;
}

@mixin transition-slow {
  transition: 0.7s ease-in-out;
}

@mixin transition-cubic {
  transition: cubic-bezier(.24,.79,.76,.29);
}