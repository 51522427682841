@mixin lux__button($bg_color: #00aed6, $text_color: #ffffff){
  display: inline-block;
  position: relative;

  padding: $lux_base;
  cursor: pointer;

  color: $text_color;
  text-transform: uppercase;
  font-size: $lux_base;
  line-height: 1;

  background: $bg_color;
  border: 2px solid $bg_color;
  border-radius: $lux_base * 0.2;

  
  &:hover {
    color: $bg_color;
    background: $text_color;
  }

  &:focus {
    outline-color: $text_color;
  }
}

@mixin lux__button-cancel(){
  @include lux__button($lux_color-tertiary, #fff);
}

@mixin lux__button-alert(){
  @include lux__button($lux_color-secondary, #fff);
}


@mixin lux__button--small(){
  padding: ($lux_base / 2) $lux_base;
  font-size: $lux_base * 0.8;
}

@mixin lux__button--icon(){
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin lux__button--square(){
  padding: 0;
  width: $lux_base * 2;
  height: $lux_base * 2;
}