$body-background: #fefefe;

$footer-background: #06143A;
$footer-text-color: #fff;
$footer-link-color: #fff;

$color-bg-accent: #FAFAFA;
$color-text-root: #222;
$color-text-root-accent: #555;

$color-primary: #189473;
$color-secondary: #AD975C;
$color-tertiary: #ffffff;
$color-quaternary: #06143A;

$pattern-1: url("/images/default/paterns/pattern--hatch.svg");
$pattern-2: url("/images/default/paterns/pattern--circles.svg");

$basic-font: 'Roboto', sans-serif;
$special-font: 'Prompt', sans-serif;